const TUIChat = {
  "撤回": "Recall message",
  "删除": "Delete message",
  "复制": "Copy",
  "已过撤回时限": "Withdrawal deadline has passed",
  "重新发送": "Resend",
  "转发": "Forward",
  "引用": "Reference",
  "回复": "Reply",
  "回复详情": "Replies",
  "条回复": "replies",
  "打开": "Open",
  "已读": "Read",
  "全部已读": "All read",
  "人已读": " read",
  "人未读": " unread",
  "选择提醒的人": "Select Contact",
  "人关闭阅读状态": " read status closed",
  "消息详情": "Message Detail",
  "关闭阅读状态": "Read status closed",
  "该消息不存在": "The message does not exist",
  "无法定位到原消息": "Unable to navigate to the original message",
  "未读": "Unread",
  "您": "You",
  "撤回了一条消息": "recalled a message",
  "重新编辑": "Re-edit",
  "我": "I",
  "查看更多": "View more",
  "转发给": "Forward to",
  "请输入消息": "Enter a message",
  "描述": "Description",
  "经度": "Longitude",
  "纬度": "Latitude",
  "自定义消息": "Custom message",
  "图片": "[photo]",
  "语音": "[audio]",
  "视频": "[video]",
  "表情": "[face]",
  "文件": "[file]",
  "自定义": "[custom message]",
  "管理员开启全员禁言": "The admin enables Mute All",
  "您已被管理员禁言": "You have been muted by the admin",
  "按Enter发送，Ctrl+Enter换行": "Press Enter to send a message and Ctrl+Enter to start a new line",
  "单击下载": "Click download",
  "确认重发该消息？": "Confirm to resend the message?",
  "取消": "Cancel",
  "确定": "Submit",
  "对方正在输入": "Typing...",
  "回到最新位置": "Back to the latest location",
  "条新消息": " new messages",
  "【安全提示】本 APP 仅用于体验旭牧通Tim IM 产品功能，不可用于业务洽谈与拓展。请勿轻信汇款、中奖等涉及钱款的信息，勿轻易拨打陌生电话，谨防上当受骗。": "【Security Tips】This APP is only used to experience the functions of Tencent Cloud Instant Messaging products, and cannot be used for business negotiation and expansion. Don't trust money-related information such as remittances and lottery wins, don't make unfamiliar phone calls easily, and beware of being deceived.",
  "点此投诉": "Complain",
  "语音通话": "Voice Call",
  "视频通话": "Video Call",
  "发起群语音": "Make group voice call",
  "发起群视频": "Make group video call",
  "已接听": "Answered",
  "拒绝通话": "Decline Call",
  "无应答": "No Answer",
  "取消通话": "Cancel Call",
  "发起通话": "Start Call",
  "结束群聊": "Edit Group Call",
  "通话时长": "Duration",
  "欢迎使用TUICallKit": "Welcome to TUICallKit",
  "检测到您暂未集成TUICallKit，无法体验音视频通话功能": "It is detected that you have not integrated TUI CallKit yet, so you cannot experience the audio and video call. ",
  "请点击": "Please click",
  "集成TUICallKit": " Integrate TUICallKit ",
  "开通腾讯实时音视频服务": "Integration Tencent Real-Time Communication",
  "进行体验": "to experience.",
  "您当前购买使用的套餐包暂未开通此功能": "The package you purchased does not support this ability.",
};

export default TUIChat;
