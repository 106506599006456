<template>
  <div :class="['home-h5']">
    
 
    <main class="home-h5-main">
      <div class="message" v-show="!currentConversationID">
        <main class="home-h5-content" v-show="currentTab === 'message'">
         
          <div class="home-h5-main-content">
            <TUIConversation :class="['TUIConversation-h5']"></TUIConversation>
          </div>
        </main>
        <main class="home-h5-content" v-show="currentTab === 'relation'">
          <header class="home-h5-main-header">
            <TUISearch :is-relation="true" />
          </header>
          <TUIContact></TUIContact>
        </main>
        <main class="home-h5-content home-h5-profile" v-show="currentTab === 'profile'">
          <MyProfile />
          <footer class="home-h5-profile-footer">
            <button class="btn" @click.prevent="exitLogin">{{ TUITranslateService.t('Home.退出登录') }}</button>
          </footer>
        </main>
  
      </div>
      <!-- 聊天入口在这里，注意需要当前的 conversationID 才能打开 chat -->
      <TUIChat :class="['TUIChat','TUIChat-h5']" v-show="currentConversationID" @closeChat="closeChat">
      </TUIChat>
    </main>
  </div>
</template>



<script setup lang="ts">

 import { ref,nextTick,onMounted } from 'vue'

import { TUIGlobal, TUIStore, StoreName, TUIConversationService, TUITranslateService } from "@tencentcloud/chat-uikit-engine";
import {
  TUIChat,
  TUIConversation,
  TUISearch,
  TUIContact,
} from "../TUIKit";
import { TUILogin } from "@tencentcloud/tui-core";




import router from "../router/index";

import { genTestUserSig } from "../TUIKit";
import { SDKAppID, secretKey } from "../main";
//import { ref } from "../TUIKit/adapter-vue";

import Header from "../components/Header.vue";

import MyProfile from './Profile.vue'

const currentTab = ref("message");
const currentConversationID = ref("");
const showShibai = ref("");

import useRoute from "../router/index";
const emits = defineEmits(["handleSwitchConversation"]);

if(useRoute.app._route.query.userId){
  const options = genTestUserSig({
    SDKAppID,
    secretKey,
    userID: useRoute.app._route.query.userId,
  });
  const loginInfo = {
    SDKAppID,
    userID: useRoute.app._route.query.userId,
    userSig: options.userSig,
    useUploadPlugin: true,
    useProfanityFilterPlugin: true
  };
    
  TUILogin.login(loginInfo)
    .then((res: any) => {
   
          if(useRoute.app._route.query.touser){
            const conversationID = 'C2C' + useRoute.app._route.query.touser;
                    TUIConversationService.getConversationProfile(conversationID)
                    .then((res: any) => {
                      TUIConversationService.switchConversation(
                        res.data.conversation.conversationID
                      );
                      emits("handleSwitchConversation", res.data.conversation.conversationID);
                    })
                    .catch((err: any) => {
                      console.warn("打开会话失败", err.code, err.msg);
                      showShibai.value=err.msg
                    });
          }
                  




    })
    .catch((error: any) => {
      console.warn("打开会话失败", error);
    });
   
}


TUIStore.watch(StoreName.CONV, {
  currentConversationID: (id: string) => {
    currentConversationID.value = id;
  },
});




const selectModel = (modelName: string) => {
  currentTab.value = modelName;
};

const closeChat = (closedConversationID: string) => {
  console.log(`conversation ${closedConversationID} has been closed.`);
};





const exitLogin = () => {
  TUILogin.logout().then(() => {
    router.push({ path: "/" });
  });
}
</script>

<style scoped lang="scss">
@import "../styles/home.scss";
@import "../styles/icon.scss";
</style>
