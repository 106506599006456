var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{ref:"list",class:[
    'TUI-conversation-list',
    _setup.isPC ? 'TUI-conversation-list-web' : 'TUI-conversation-list-h5',
  ],on:{"mousewheel":_setup.scrollChange,"scroll":_setup.scrollChange,"click":_setup.closeToggleListItem}},_vm._l((_setup.conversationList),function(conversation,index){return _c('li',{key:conversation.conversationID,staticClass:"TUI-conversation-content",class:[!_setup.isPC && 'TUI-conversation-content-h5']},[_c('div',{class:[
        'TUI-conversation-item',
        _setup.currentConversationID === conversation.conversationID &&
          'TUI-conversation-item-selected',
        _setup.toggleID === conversation.conversationID &&
          'TUI-conversation-item-toggled',
        conversation.isPinned && 'TUI-conversation-item-pinned',
      ],on:{"click":function($event){return _setup.handleSwitchConversation(conversation.conversationID)},"longpress":function($event){return _setup.handleToggleListItem($event, conversation, index, true)},"contextmenu":function($event){$event.preventDefault();return _setup.handleToggleListItem($event, conversation, index)},"touchstart":function($event){return _setup.handleH5LongPress($event, conversation, index, 'touchstart')},"touchend":function($event){return _setup.handleH5LongPress($event, conversation, index, 'touchend')},"mouseover":function($event){return _setup.handleH5LongPress($event, conversation, index, 'touchend')}}},[_c('aside',{staticClass:"left"},[_c('img',{staticClass:"avatar",attrs:{"src":conversation.getAvatar()}}),(conversation.unreadCount > 0 && !conversation.isMuted)?_c('span',{staticClass:"num"},[_vm._v(" "+_vm._s(conversation.unreadCount > 99 ? "99+" : conversation.unreadCount)+" ")]):_vm._e(),(conversation.unreadCount > 0 && conversation.isMuted)?_c('span',{staticClass:"num-notify"}):_vm._e()]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-header"},[_c('label',{staticClass:"content-header-label"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(conversation.getShowName()))])]),_c('div',{staticClass:"middle-box"},[(
                conversation.type === 'GROUP' &&
                conversation.groupAtInfoList &&
                conversation.groupAtInfoList.length > 0
              )?_c('span',{staticClass:"middle-box-at"},[_vm._v(_vm._s(conversation.getGroupAtInfo()))]):_vm._e(),_c('p',{staticClass:"middle-box-content"},[_vm._v(" "+_vm._s(conversation.getLastMessage('text'))+" ")])])]),_c('div',{staticClass:"content-footer"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(conversation.getLastMessage('time')))]),(conversation.isMuted)?_c(_setup.Icon,{attrs:{"file":_setup.muteIcon}}):_vm._e()],1)])]),(conversation.conversationID === _setup.toggleID)?_c('div',{ref:"dialog",refInFor:true,class:['dialog', 'dialog-item'],style:({ top: `${_setup.dialogTop}px` })},[_c('p',{staticClass:"conversation-options",on:{"click":function($event){$event.stopPropagation();return _setup.handleItem({ name: _setup.CONV_OPERATION.DELETE, conversation })}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIConversation.删除会话"))+" ")]),(!conversation.isPinned)?_c('p',{staticClass:"conversation-options",on:{"click":function($event){$event.stopPropagation();return _setup.handleItem({ name: _setup.CONV_OPERATION.ISPINNED, conversation })}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIConversation.置顶会话"))+" ")]):_vm._e(),(conversation.isPinned)?_c('p',{staticClass:"conversation-options",on:{"click":function($event){$event.stopPropagation();return _setup.handleItem({ name: _setup.CONV_OPERATION.DISPINNED, conversation })}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIConversation.取消置顶"))+" ")]):_vm._e(),(
          conversation.messageRemindType === '' ||
          conversation.messageRemindType === 'AcceptAndNotify'
        )?_c('p',{staticClass:"conversation-options",on:{"click":function($event){$event.stopPropagation();return _setup.handleItem({ name: _setup.CONV_OPERATION.MUTE, conversation })}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIConversation.消息免打扰"))+" ")]):_vm._e(),(conversation.isMuted)?_c('p',{staticClass:"conversation-options",on:{"click":function($event){$event.stopPropagation();return _setup.handleItem({ name: _setup.CONV_OPERATION.NOTMUTE, conversation })}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIConversation.取消免打扰"))+" ")]):_vm._e()]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }