import translate from "./translate"

const TUIContact = {
  "群聊通知": "群聊通知",
  "系统通知": "系统通知",
  "我的群聊": "我的群聊",
  "输入群ID，按回车搜索": "输入群ID，按回车搜索",
  "群名称": "群名称",
  "群ID": "群ID",
  "加入群聊": "加入群聊",
  "解散群聊": "解散群聊",
  "退出群聊": "退出群聊",
  "进入群聊": "进入群聊",
  "群类型": "群类型",
  "请填写验证信息": "请填写验证信息",
  "申请加入": "申请加入",
  "已申请":"已申请",
  "接受": "接受",
  "拒绝": "拒绝",
  "已处理": "已处理",
  "添加群聊": "添加群聊",
  "已加入": "已加入",
  "该群组不存在": "该群组不存在",
  "加群": "加群",
  "我的好友": "我的好友",
  "发送消息": "发送消息",
  "个性签名": "个性签名"
}

export default TUIContact;
