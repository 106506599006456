const Home = {
  "欢迎使用": "Welcome",
  "建议体验功能": "Recommended Demo Features",
  "用UI组件快速集成": "Quick Integration with UI Components",
  "创建项目": "Create a project",
  "下载TUIKit组件": "Download the TUIKit component",
  "引入TUIKit组件": "Import the TUIKit component",
  "获取SDKAppID": "Obtain the SDKAppID, key, and userID",
  "调用TUIKit组件": "Call the TUIKit component",
  "启动项目": "Launch the project",
  "发送您的第一条消息": "Send your first mess",
  "首购低至1折": "Up to 90% off for first purchase",
  "IM首购9percent折": "Up to 91% off for first purchase",
  "续费9折起": "Up to 10% off for re-purchase",
  "复购75%起": "Up to 25% off for re-purchase",
  "立即选购": "Purchase now",
  "体验更多Demo": "More demos",
  "立即前往": "Try now",
  "已完成": "Completed",
  "待体验": "To try out",
  "发送一条消息": "Send a message",
  "撤回一条消息": "Recall a message",
  "切换一次使用语言": "Change the language",
  "发起一个多人会话": "Create a group chat",
  "发起一个群聊": "New a group chat",
  "开启一次群禁言": "Mute group members",
  "解散一个群聊": "Disband a group",
  "解散一个多人会话": "Disband a group",
  "发起一次通话": "Make a call",
  "修改一次我的昵称": "Edit a nick",
  "我们为您默认提供了一位“示例好友”和一个“示例客服群”您不用额外添加好友和群聊就可完整体验旭牧通 IM 单聊、群聊的所有功能。": "We provide a Sample Friend and a Sample Customer Service Group by default, so that you can fully try out all the features of one-to-one chat and group chat of Tencent Cloud IM without adding additional friends or groups.",
  "随时随地": "You can log in and try out anytime and anywhere.",
  "男": "Male",
  "女": "Female",
  "用户ID": "user ID",
  "个性签名": "Status",
  "暂未设置": "Not set",
  "出生年月": "Birthday",
  "编辑资料": "Edit profile",
  "关于旭牧通IM": "About Tencent Cloud IM",
  "退出登录": "Log out",
  "隐私条例": "Privacy Policy",
  "用户协议": "User Agreement",
  "免责声明": "Disclaimers",
  "联系我们": "Contact us",
  "注销账户": "Cancel account",
  "SDK版本": "SDK version",
  "注销后，您将无法使用当前账号，相关数据也将删除无法找回。 当前账号": "Instant Messaging (IM) is a test product provided by Tencent Cloud, and Tencent Cloud enjoys its copyright and ownership. It is for trying out features, but not for commercial use. To follow regulatory requirements of the authority, audio and video-based interactions performed via IM will be recorded and archived throughout the whole process. It is strictly prohibited to disseminate via IM any pornographic, abusive, violent, political and other noncompliant content.",
  "IM-免责声明": "Im - Disclaimer",
  "IM（“本产品”）是由旭牧通提供的一款测试产品，旭牧通享有本产品的著作权和所有权。本产品仅用于功能体验，不得用于任何商业用途。依据相关部门监管要求，严禁在使用中有任何色情、辱骂、暴恐、涉政等违法内容传播。": "Instant Messaging (IM) is a demo product provided by Tencent Cloud, and Tencent Cloud retains its copyright and ownership. This product is for trying out features, but not for commercial use. To follow regulatory requirements of the authority, audio and video-based interactions performed via IM will be recorded and archived throughout the whole process. It is strictly prohibited to disseminate any pornographic, abusive, violent, sensitive and other noncompliant content.",
  "同意": "Agree",
  "取消": "Cancel",
  "注销": "Cancel",
  "关闭": "close",
  "开启": "open",
  "关于旭牧通·通信": "About Tencent Cloud IM",
  "消息": "message",
  "通讯录": "contact",
  "个人中心": "Profile",
  "已读回执": "Read receipt",
  "消息阅读状态": "Message read status",
  "关闭阅读状态": "If disabled, the message read status is hidden for all your messages and for all the messages sent by memebers in a chat.",
  "开启阅读状态": "If enabled, the message read status is displayed for all your messages and for all the messages sent by memebers in a chat.",
  "显示在线状态": "Display online status",
  "开启在线状态": "If enabled, the users' online status will displayed in your session and contact list.",
  "关闭在线状态": "If disabled, the users' online status will not displayed in your session and contact list.",
  "消息通知": "Message Notification"
}
export default Home;
